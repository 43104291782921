import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="gallery" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
        </div>
        <div className="row">
          <div className="gallery-items">
            {props.data
              ? props.data.map((d, i) => (
                    <Image
                      id={i}
                      title={d.title}
                      image={d.image}
                    />
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
