import React from "react";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="row container">
        <div className="navbar-header">
        <ul className="nav navbar-left">
            <li>
                <a id="Logo" href="https://www.tech2us.ma" label="Tech2us">
                <img alt="" itemprop="logo" className="logo" src="images/logo.png"/>
            </a>
            </li>
          </ul>

          <ul className="nav navbar-right">
            <li className="nav-item-right">
              <a href="#about">
                About
              </a>
            </li>
            <li className="nav-item-right">
              <a href="#services">
                Services
              </a>
            </li>
            <li className="nav-item-right">
              <a href="#gallery">
                Gallery
              </a>
            </li>
            <li className="nav-item-right">
              <a href="#team">
                Team
              </a>
            </li>
            <li className="nav-item-right">
              <a href="#contact">
                Contact
              </a>
            </li>
          </ul>
          </div>

        </div>
    </nav>
  );
};
